(function($){
          $(function(){ 
            var scroll = $(document).scrollTop();
            var headerHeight = $('.fixed-top').outerHeight();
             
            $(window).scroll(function() {
              var scrolled = $(document).scrollTop();
              if (scrolled > headerHeight){
                $('.blueHeader').next().addClass('weg');
              } else {
                $('.blueHeader').next().removeClass('weg');
              }
         
                if (scrolled > scroll){
                $('.blueHeader').next().removeClass('rein');
                } else {
                $('.blueHeader').next().addClass('rein');
                }            
              scroll = $(document).scrollTop(); 
             });
                
           });
})(jQuery);